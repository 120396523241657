.root {
  padding: 60px 0;
  text-align: center;
  background: #ffffff;
}

.logo {
  max-width: 170px;
  height: 23px;
  margin-bottom: 1.5rem;
}

.authorTagline {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: center;
  letter-spacing: -0.025em;
}

.heading > span {
  color: #f95858;
}

.accolades {
  margin: 0 auto 40px;
  max-width: 800px;
  padding: 0 20px;
  position: relative;
}

.accolade {
  left: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  transition: opacity 0.25s linear;
}

.hiddenAccolade {
  composes: accolade;
  opacity: 0;
}

.sizerAccolade {
  transition: opacity 0.25s linear;
}

.hiddenSizerAccolade {
  composes: sizerAccolade;
  opacity: 0;
}

.quote {
  color: #3e3f3d;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  padding-bottom: 1.5rem;
  text-align: center;
  letter-spacing: -0.025em;
}

.source {
  color: #9e9f9e;
  font-size: 0.875rem;
  font-style: normal;
}

.logos {
  align-content: center;
}

.logos > img {
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  vertical-align: middle;
}

.logoNetflix {
  width: 75px;
  height: 26px;
}

.logoWalmart {
  width: 99px;
  height: 25px;
}

.logoNike {
  width: 49px;
  height: 29px;
}

.logoMicrosoft {
  width: 100px;
  height: 23px;
}

.logoIheartmedia {
  width: 119px;
  height: 16px;
}

.logoGoogle {
  width: 70px;
  height: 26px;
}

@media (min-width: 561px) {
  .quote {
    font-size: 1.5rem;
  }
}

@media (min-width: 769px) {
  .quote {
    font-size: 2rem;
  }

  .heading {
    margin-bottom: 70px;
  }

  .accolades {
    margin-bottom: 70px;
  }

  .logoNetflix {
    width: 125px;
    height: 43px;
  }

  .logoWalmart {
    width: 164px;
    height: 41px;
  }

  .logoNike {
    width: 81px;
    height: 47px;
  }

  .logoMicrosoft {
    width: 154px;
    height: 36px;
  }

  .logoIheartmedia {
    width: 170px;
    height: 23px;
  }

  .logoGoogle {
    width: 106px;
    height: 40px;
  }
}
